import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Button, Row, Col, Alert, DropdownButton, Dropdown,Toast} from 'react-bootstrap'
import { cap } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import logoig from '../assets/img/nasta/logoig.svg'
import burung from '../assets/img/nasta/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar, pw } from '../params'
import { Timer } from '../components/timer'
import post from '../params/post'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import covid from '../assets/img/nasta/covid.png'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import getData from '../params/getdata'


let cmain = '#BA254A'
let black = 'rgb(38,38,38)'
let mainfont = "#BA254A"
let id = 'aylya-alfian'
let inisial_co = 'Alfian'
let inisial_ce = 'Aylya'
let lengkap_co = 'Alfian Usman, S. Sn'
let lengkap_ce = 'Aylya Rahma, A.Md. Keb'
let bapak_co = 'Bpk. Haidar (Alm)'
let ibu_co = 'Ibu Sudarwiarsih'
let bapak_ce = "Bpk. Wahyu Mustika Rahmad Hutapea"
let ibu_ce = "Ibu Vivaningsih, STr. Keb"
let ig_co = "alfiansmn"
let ig_ce = "aylyarahma"
let foto_ce = pw(id, "ce.jpg")
let foto_co = pw(id, "co.jpg")
let waktunikah = "01/09/2021"

let modal = pw(id, "modal.jpg")
let openlogo = pw(id, "logo.png")

let gmaps = "https://goo.gl/maps/ucfHRP3D5mwjAeUz9"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=MDFvZjYxOG5mY3BjNXFqdm5hdG1iMWtkNmQgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'
let gmaps1 = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.487202439409!2d106.796923214769!3d-6.199272195512168!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f6be661e647d%3A0x13f96eb8f8a7bd2d!2sIbis%20Hotel%20Slipi!5e0!3m2!1sid!2sid!4v1609345199210!5m2!1sid!2sid" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>`
gmaps1 = gmaps1.split('"')[1]


export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 0,
            show:false
        }
    }

    async componentDidMount() {
        try {
            AOS.init({
                // initialise with other settings
                duration: 2000
            });
            let comment = await getData(`kepada:"${id}"`)
            this.setState({ comment: comment })
        } catch (error) {
            console.log(error)
        }
    }
    dropdown = () => {
        let { friend } = this.state
        let item = []
        for (let index = 1; index < 3; index++) {
            item[index] = (<Dropdown.Item onClick={() => {
                this.setState({ friend: index })
            }}>{index}</Dropdown.Item>)
        }
        item.push(<Dropdown.Item onClick={() => {
            this.setState({ friend: 'tentative' })
        }}>tentative</Dropdown.Item>)
        item.push(<Dropdown.Item onClick={() => {
            this.setState({ friend: 'tidak hadir' })
        }}>Tidak Hadir</Dropdown.Item>)
        return (
            <>
                <Item>
                    <p style={{ fontSize: '16px', color: cmain }} className="mb-0 w-100 text-center">
                        How many people you coming with?
          </p>
                    <DropdownButton id="dropdown-basic-button" title={friend == null ? '1' : friend}>
                        {
                            item
                        }
                    </DropdownButton>
                </Item>
            </>
        )
    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id, "music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${encodeURI(this.nama.current.value)}", hadir: "${this.state.friend}", jumlahhadir: "", kepada: "${id}", pesan:"${encodeURI(this.pesan.current.value)}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            } else {
                                err.push('Koneksi Gagal')
                            }


                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    render() {
        let { showrek, days, hours, minutes, seconds, hide, submitted, err, comment ,show} = this.state
        let slider = []
        let slide = [
            "DSC07020ps.jpg",
            "DSC07054.jpg",
            "DSC07173.jpg",
            "DSC07181.jpg",
            "DSC07197.jpg",
            "DSC07217.jpg",
            "DSC07223.jpg",
            "DSC07259.jpg",
            "DSC07264.jpg",
            "DSC07276.jpg",

        ]
        slide.map(v => {
            slider.push(gambar(pw(id, v), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1440&h=720'))
        })
        let query = this.useQuery().get('u');
        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slider[0]}
                    url={`https://undang.in/${id}`}
                >
                    <link rel="preconnect" href="https://fonts.gstatic.com" />
                    <link href="https://fonts.googleapis.com/css2?family=Arizonia&display=swap" rel="stylesheet"></link>
                </Helm>

                <div id='gold5' style={{
                    backgroundImage: `url(${'https://i.ibb.co/gmz96rb/Background-Putih.jpg'})`,
                    backgroundSize: 'cover', backgroundPosition: 'center'
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='relative' style={{
                            backgroundImage: `url('${modal}')`
                        }}>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-4'
                                        src={gambar(openlogo)} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                                        Kepada Yth :<br /> {query ? query : ''} <br /></h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white' }}>
                                    Open Invitation
                            </div>
                            </Row>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: '#DDB6B1' }}>
                                <Container className="dinny px-3 pt-5 ">
                                    <Item>

                                        <p className="fs16 text-center px-3" style={{ color: 'white' }}>
                                            And one of His signs is that He created for you spouses from among yourselves so that you may find comfort in them. And He has placed between you compassion and mercy. Surely in this are signs for people who reflect.<br /><br />(Ar- Rum 21)

                                        </p>
                                    </Item>

                                    <Item>
                                        <Col xs={6} md={4}>
                                            <img src='https://webstockreview.net/images/clipart-flower-hipster-2.png' data-aos="zoom-in"
                                                data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>

                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: 'white' }}>
                                            The Intimate Wedding of <br />
                                            <img src={pw(id, "logo1.png")} className="img-fluid" style={{ width: '400px' }} />
                                        </p>
                                    </Item>
                                </Container>
                                <Container id='sectiongold55' className="py-5 dinny" >

                                    <Item>
                                        <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{
                                                        fontSize: '72px', color: cmain,
                                                        fontFamily: 'Parisienne, cursive'
                                                    }}>
                                                        {inisial_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_ce, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center"
                                                        style={{ fontSize: '24px', fontFamily: 'Parisienne, cursive', color: cmain }}>
                                                        {lengkap_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                        <b>Putri pertama dari :</b><br />
                                                        {bapak_ce}  <br />
                                                        &<br />
                                                        {ibu_ce}
                                                    </p>
                                                </Item>
                                                <Item>

                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                        <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', fontFamily: 'Parisienne, cursive', color: cmain }}>
                                                        {inisial_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_co, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" style={{
                                                        fontSize: '24px',
                                                        fontFamily: 'Parisienne, cursive', color: cmain
                                                    }}>
                                                        {lengkap_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                        <b>Putra ketiga dari:</b><br />
                                                        {bapak_co}
                                                        <br />
                                                        &<br />
                                                        {ibu_co}
                                                    </p>
                                                </Item>
                                                <Item>
                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                    </Item>
                                </Container>
                                <Container fluid className="text-center px-4 dinny" style={{ color: mainfont, fontFamily: 'Patrick Hand, cursive' }} >
                                    <Item>
                                        <p className="fs20" data-aos="fade-left">
                                            Yang Insyaa Allah akan dilaksanakan pada:
                    </p>
                                    </Item>
                                    <Item>
                                        <p className="fs24" data-aos="fade-right">
                                            <b>
                                                SABTU <span className="fs36">09</span> JAN 2021
                      </b>
                                        </p>
                                    </Item>
                                    <Item>
                                        <p className="fs24 col-sm-4" style={{ color: mainfont }} data-aos="fade-left">
                                            <b>Akad Nikah </b><br />
                                            <span className=" fs20">
                                                10.00 - 11.30 WIB
                      </span><br />

                                        </p>
                                        <p className="px-3 d-none d-sm-block" style={{ color: mainfont, fontSize: '72px' }}>
                                            \
                    </p>
                                        <div data-aos="fade-right" className="col-8 d-sm-none" style={{ borderBottom: `2px solid ${cmain}` }}>
                                        </div>
                                        <p className="fs20 pt-3 pt-sm-0 col-sm-4" style={{ color: mainfont }} data-aos="fade-left">
                                            <b>Resepsi</b><br />
                                            <span className=" fs20">
                                                Sesi Pertama Pukul 13.00 - 14.00 WIB<br />
Sesi Kedua Pukul 14.00 - 15.00 WIB
                      </span>
                                        </p>
                                    </Item>
                                    <Item>
                                        <div className="mapouter m-3"><div className="gmap_canvas text-center">
                                            <iframe width="400" height="300" id="gmap_canvas"
                                                src={gmaps1} frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                                            </iframe></div>
                                        </div>
                                    </Item>
                                    <Item>
                                        <p className="fs16 pt-3 w-100 text-center" data-aos="fade-right">
                                            <b>Ibis Hotel</b><br />
Jl. Letjen S. Parman Kav. 59 Slipi, Palmerah - Jakarta Barat<br />
                                            <span style={{ fontStyle: 'italic' }}>
                                                Undangan Hanya untuk 2 orang
</span>
                                        </p>

                                    </Item>
                                    <Item>
                                        <Col xs={10} sm={3} data-aos="fade-left"
                                            style={{
                                                border: `2px solid ${mainfont}`,
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => {
                                                window.open(gmaps)
                                            }}
                                            className="p-2 mx-sm-2 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: mainfont }}>
                                                    <b>Get Direction</b>
                                                </p>
                                            </Item>
                                        </Col>
                                        <Col
                                        data-aos="fade-right"
                                            onClick={() => window.open(gcalendar)}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${mainfont}`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: mainfont }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>
                                </Container>
                                <Timer cmain={cmain} waktunikah={waktunikah} />


                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Slider slide={slider} />
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3'>

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4 fs14'>
                                                            “Love recognizes no barriers. It jumps hurdles, leaps fences, penetrates walls to arrive at its destination full of hope.”
<br />– Maya Angelou

</p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>

                                <Container id='sectiongold58' >

                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                        <Item>
                                            <Col xs={4} lg={2}>
                                                <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <div className='col-12 col-lg-10 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center" style={{
                                                        fontFamily: '"Marck Script", cursive',
                                                        color: cmain
                                                    }}>
                                                        Send Your Wishes
                                                     </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={12} md={6}>
                                                        <form className="col-12 w-100">
                                                            <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                            <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                            {
                                                                this.dropdown()
                                                            }
                                                            <Item>
                                                                <Col xs={12} className=''>
                                                                    {
                                                                        submitted == true ? (
                                                                            <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                                Pesan anda sudah disampaikan
                                                                            </Alert>) : (submitted === false ? (
                                                                                <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                    {
                                                                                        err.map(val => {
                                                                                            return (
                                                                                                <li>{val}</li>
                                                                                            )
                                                                                        })
                                                                                    }

                                                                                </Alert>
                                                                            ) : false)
                                                                    }

                                                                </Col>
                                                            </Item>
                                                            <Item>
                                                                <div className='col-6 button rounded btn'
                                                                    onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                            </Item>
                                                        </form>

                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <div className='col-12 kotak pb-4 pt-4 mt-3 mt-md-0' data-aos="left-left" data-aos-duration="1000" style={{ height: '500px', overflowY: 'scroll', overflowX: 'hidden' }}>
                                                            {comment ? comment.map(v => {
                                                                return (
                                                                    <Item>
                                                                        <Col xs={2}>
                                                                            <img src='https://www.flaticon.com/svg/static/icons/svg/3447/3447670.svg' className="img-fluid w-100" />
                                                                        </Col>
                                                                        <Col xs={10}>
                                                                            <Alert variant="secondary col-12">
                                                                                <p style={{ fontSize: '16px' }} className='m-0'>
                                                                                    {decodeURI(v.pesan)}
                                                                                    <br/>
                                                                                    <span style={{fontStyle:'italic'}}>
                                                                                  <b>
                                                                                  {decodeURI(v.dari)}
                                                                                  </b>
                                                                                    </span>
                                                                                </p>
                                                                            </Alert>
                                                                        </Col>
                                                                    </Item>
                                                                )
                                                            }) : false

                                                            }
                                                        </div>
                                                    </Col>
                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>

                                <Container className="py-3" fluid style={{ backgroundColor: 'white' }}>
                  <Item>
                    <Col
                      onClick={() => this.setState({ showrek: !showrek })}
                      xs={10} md={4}
                      style={{
                        border: `2px solid ${cmain}`,
                        borderRadius: '10px'
                      }}
                      className="p-2 mx-md-2 mt-3 mt-md-0">
                      <Item>
                        <img src="https://www.flaticon.com/svg/static/icons/svg/1139/1139982.svg" className="img-fluid"
                          style={{ width: "10%", height: '10%' }} />
                        <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                          <b>Send Gift (Klik Disini)</b>
                        </p>
                      </Item>
                    </Col>
                  </Item>
                </Container>                            
                               {showrek?(
                                    <Container fluid style={{ backgroundColor: 'white' }}
                                    className={`text-center mt-3 aulia-wedding py-3 px-3 `} >
                                    
                                        <Col className="py-3 px-md-5 mx-2" style={{ backgroundColor: cmain, borderRadius: '10px' }}>
                                            <h1>
                                                Wedding Gift
                                            </h1>
                                            
                                            
                                                <p className="text-center w-100">
                                                    BNI - 0239287957 <br />
                                                        (AYLYA RAHMA)
                                                    </p>
                                                <Item>
                                                <Toast onClose={() => this.setState({ show: false })} show={this.state.show} delay={3000} autohide >
                                                    <Toast.Body>Copied!</Toast.Body>
                                                </Toast>
                                                </Item>
                                                <Item>
                                                <CopyToClipboard text="0239287957">
                                                    <Col xs={8} className='btn button mb-5 mt-1'
                                                        onClick={(e) => {
                                                            this.setState({ show: true })

                                                        }}>
                                                        Salin No. Rekening
                          </Col>
                                                </CopyToClipboard>
                                                    </Item>
                                                <p className="text-center w-100">
                                                    Ovo QR barcode <br />
                                                    081220822967
                                                a.n. AYLYA RAHMA
                          </p>
                                                <Item>
                                                    <img src={pw(id, "qr.PNG")} style={{ width: '300px', height: '300px' }} />
                                                </Item>

                                                <p className="text-center w-100">
                                                    <br/>
                                                    Alamat Pengiriman Kado
                          </p>
                                                <p className="text-center w-100 px-3">
                                                    <b>
                                                        Jl. Hanggada II No. 5 RT 001 RW 010, Cibodas baru, Cibodas, Kota Tangerang Banten kode pos 15138
                            </b>
                                                </p>
                                                <Item>
                                                <Toast onClose={() => this.setState({ show: false })} show={this.state.show} delay={3000} autohide >
                                                    <Toast.Body>Copied!</Toast.Body>
                                                </Toast>
                                                </Item>
                                                <Item>
                                                <CopyToClipboard text="Jl. Hanggada II No. 5 RT 001 RW 010, Cibodas baru, Cibodas, Kota Tangerang Banten kode pos 15138">
                                                    <Col xs={8} className='btn button mb-5 mt-1'
                                                        onClick={(e) => {
                                                            this.setState({ show: true })

                                                        }}>
                                                        Salin Alamat
                          </Col>
                                                </CopyToClipboard>
                                                    </Item>
                                           
                                        </Col>
                                 

                                  

                                </Container>


                               ):false}

                                <Container classname="py-3" style={{ color: cmain }}>
                                    <p className="text-center w-100" style={{ fontFamily: 'Patrick Hand, cursive' }} >
                                        Best Regards
                    </p>
                    <Item>
                                        <Col xs={12} md={4}>
                                            <img src={pw(id,"aylia.png")} className="w-100 img-fluid p-1"/>
                                        </Col>
                                    </Item>
                                    <h1 className="text-center w-100" style={{ fontFamily: 'Patrick Hand, cursive' ,fontSize:'24px' }}>
                                        #AyPiongInLove
                    </h1>


                                </Container>

                                <Foot ig={logoig} dark />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

